import styled from 'styled-components';

export const FeatureSection = styled.div`
  position: relative;
`;

export const FeatureImg = styled.img`
  width: 100%;
  height: 21rem;
  object-fit: cover;
  @media (min-width: 1200px) {
    height: 43.7rem;
  }
`;

export const FeatureText = styled.div`
  width: auto;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  margin: -21rem 1.4rem 0;
  > div {
    padding: 4.1rem 0 0rem;
  }
  @media (min-width: 1200px) {
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30rem;
    right: 30rem;
    display: flex;
    align-items: center;
  }
`;

export const Title = styled.h2`
  font-family: 'Heuristica';
  font-weight: 600;
  font-size: 1.8rem;
  color: ${props => props.theme.white};
  text-align: center;
  letter-spacing: 0;
  line-height: 4.4rem;
  margin: 0;
  @media (min-width: 1200px) {
    font-family: 'Avenir Book';
    font-weight: 400;
    font-size: 3.4rem;
    margin-bottom: 4.1rem;
  }
`;

export const Text = styled.p`
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.1rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 1.6rem;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  } 
`;

export const ClientsBox = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto;
  grid-gap: 0;
  box-sizing: border-box;
  margin: 0.1rem;
  @media (min-width: 1200px) {
    grid-template-columns: auto auto auto auto;
  }
`;

export const Cell = styled.div`
  padding: 4.8rem 2rem;
  border-right: 1px solid ${props =>props.theme.borderColor};
  border-bottom: 1px solid ${props =>props.theme.borderColor};
  height: 14.8rem;
  width: 16.1rem;
  &.last-row {
    border-bottom: 0;
  }
  &.last-column {
    border-right: 0;
  }
  @media (min-width: 1200px) {
    padding: 5rem 4rem;
    height: 15.5rem;
    width: 21.3rem;
  }
`;

export const ClientLogo = styled.img`
  height: 4.8rem;
  outline: none;
  width: 12rem;
  @media (min-width: 1200px) {
    width: 13.2rem;
    height: 5.2rem;
  }
`;

export const ClientsArea = styled.div`
  .carousel-slider {
    padding-bottom: 5rem;
    .control-dots {
      .dot {
        box-shadow: none;
        width: 1rem;
        height: 1rem;
        background-color: ${props => props.theme.quoteTxtColor};
        opacity: 0.2;
        transition: opacity 0.5s linear;
        &.selected {
          opacity: 1;
        }
      }
    }
  }
  .carousel {
    .slide {
      background-color: ${props => props.theme.white};
    }
  }
`;
