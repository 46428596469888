import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import {
  Container,
  Section,
  Subtitle as MainSubTitle,
} from '@components/styledComponents/index';
import {
  Title,
  Text,
  FeatureSection,
  FeatureImg,
  FeatureText,
  ClientsBox,
  ClientLogo,
  Cell,
  ClientsArea,
} from './styles';
import {
  CustomerDetails,
  CustomerDetailsBox,
  CustomerImage,
  CustomerInfo,
  CustomerLogo,
  CustomerName,
  CustomerPosition,
  TestimonialBox,
  TestimonialText,
} from '@components/pages/home/styles';
import AppContext from '@utils/app-context';
import { desktopBaseWidth } from '@utils/consts';

const clients = [
  {
    items: [
      {
        id: 1,
        image: require('@images/clients/samba.png'),
        image2x: require('@images/clients/sambax2.png'),
      },
      {
        id: 2,
        image: require('@images/clients/almarai.png'),
        image2x: require('@images/clients/almaraix2.png'),
      },
      {
        id: 3,
        image: require('@images/clients/arabi_anb.png'),
        image2x: require('@images/clients/arabi_anbx2.png'),
      },
      {
        id: 4,
        image: require('@images/clients/aljazira-bank.png'),
        image2x: require('@images/clients/aljazira-bankx2.png'),
      },
      {
        id: 5,
        image: require('@images/clients/alrajhi-bank.png'),
        image2x: require('@images/clients/alrajhi-bankx2.png'),
      },
      {
        id: 6,
        image: require('@images/clients/saudi-fransi-bank.png'),
        image2x: require('@images/clients/saudi-fransi-bankx2.png'),
      },
      {
        id: 7,
        image: require('@images/clients/mitsubishi.png'),
        image2x: require('@images/clients/mitsubishix2.png'),
      },
      {
        id: 8,
        image: require('@images/clients/saudi-investment-bank.png'),
        image2x: require('@images/clients/saudi-investment-bankx2.png'),
      },
    ],
  },
  {
    items: [
      {
        id: 9,
        image: require('@images/clients/bidaya.png'),
        image2x: require('@images/clients/bidayax2.png'),
      },
      {
        id: 10,
        image: require('@images/clients/saudi-custom.png'),
        image2x: require('@images/clients/saudi-customx2.png'),
      },
      {
        id: 11,
        image: require('@images/clients/zakat-tax.png'),
        image2x: require('@images/clients/zakat-taxx2.png'),
      },
      {
        id: 12,
        image: require('@images/clients/hrdf.png'),
        image2x: require('@images/clients/hrdfx2.png'),
      },
      {
        id: 13,
        image: require('@images/clients/mof.png'),
        image2x: require('@images/clients/mofx2.png'),
      },
      {
        id: 14,
        image: require('@images/clients/moj.png'),
        image2x: require('@images/clients/mojx2.png'),
      },
      {
        id: 15,
        image: require('@images/clients/nhc.png'),
        image2x: require('@images/clients/nhcx2.png'),
      },
      {
        id: 16,
        image: require('@images/clients/nwc.png'),
        image2x: require('@images/clients/nwcx2.png'),
      },
    ],
  },
  {
    items: [
      {
        id: 17,
        image: require('@images/clients/sama.png'),
        image2x: require('@images/clients/samax2.png'),
      },
      {
        id: 18,
        image: require('@images/clients/stc.png'),
        image2x: require('@images/clients/stcx2.png'),
      },
      {
        id: 19,
        image: require('@images/clients/thiqah.png'),
        image2x: require('@images/clients/thiqahx2.png'),
      },
      {
        id: 4,
        image: require('@images/clients/aljazira-bank.png'),
        image2x: require('@images/clients/aljazira-bankx2.png'),
      },
      {
        id: 5,
        image: require('@images/clients/alrajhi-bank.png'),
        image2x: require('@images/clients/alrajhi-bankx2.png'),
      },
      {
        id: 6,
        image: require('@images/clients/saudi-fransi-bank.png'),
        image2x: require('@images/clients/saudi-fransi-bankx2.png'),
      },
      {
        id: 7,
        image: require('@images/clients/mitsubishi.png'),
        image2x: require('@images/clients/mitsubishix2.png'),
      },
      {
        id: 8,
        image: require('@images/clients/saudi-investment-bank.png'),
        image2x: require('@images/clients/saudi-investment-bankx2.png'),
      },
    ],
  },
];

const getCellClass = (index, length, cellCount) => {
  let className = '';
  if (index % cellCount == 0) {
    className += 'last-column';
  }
  if (
    (length % cellCount === 0 && index > length - cellCount) ||
    index > length - (length % cellCount)
  ) {
    className += ' last-row';
  }
  return className;
};

const Clients = () => {
  const theme = useContext(ThemeContext);
  const [cellCount, setCellCount] = useState(2);
  const {
    size: { width },
  } = useContext(AppContext);

  useEffect(() => {
    const count = width > desktopBaseWidth ? 4 : 2;
    setCellCount(count);
  }, [width]);

  return (
    <>
      <FeatureSection>
        <FeatureImg
          alt="Clients page"
          src={require('@images/clients-bg.jpg')}
          srcSet={`${require('@images/clients-bg.jpg')}, ${require('@images/clients-bg@2x.jpg')} 2x`}
        />
        <FeatureText>
          <div>
            <Title>Meet our thriving customer base</Title>
            <Text>
              Our clients want best-of-breed technology, with a local touch.
              We’re helping some of the GCC’s top enterprises transform their IT
              infrastructure with a solutions-neutral, modular approach — and a
              vision to connect IT with the rest of the business. Here are just
              a few of our satisfied clients
            </Text>
          </div>
        </FeatureText>
      </FeatureSection>
      <Container deskDir={'column'}>
        <Section>
          <MainSubTitle align="center" deskAlign={'center'}>
            Our Clients
          </MainSubTitle>
        </Section>
        <Section padding={'0 1.4rem 3.5rem'}>
          <ClientsArea>
            <Carousel
              showStatus={false}
              infiniteLoop={true}
              showArrows={true}
              interval={3000}
              autoPlay={true}
              showThumbs={false}
            >
              {clients.map(({ items }, index) => (
                <ClientsBox key={index}>
                  {items.map((item, index) => (
                    <Cell
                      key={item.id}
                      className={getCellClass(
                        index + 1,
                        items.length,
                        cellCount
                      )}
                    >
                      <ClientLogo
                        alt={item.image}
                        src={item.image}
                        srcSet={`${item.image}, ${item.image2x} 2x`}
                      />
                    </Cell>
                  ))}
                </ClientsBox>
              ))}
            </Carousel>
          </ClientsArea>
        </Section>
      </Container>
      {/* <Container
        bgColor={theme.secondaryBgColor}
        deskDir={'row'}
        deskMargin={'0 8rem 0 0'}
      >
        <Section padding={'0'} deskMargin={'2.7rem 1.4rem 16rem'}>
          <TestimonialBox bgColor={'transparent'} full={true}>
            <TestimonialText>
              “Thank you so much for your visit last week and for your honest,
              clear and professional advice. We were very impressed with how
              down-to-earth and straightforward you were in sharing your
              knowledge of planning etc with us. Having visited a previous
              client of Offset today, speaking with her and seeing the work
              done, we are now even more impressed and would like to use Offset
              for plans”
            </TestimonialText>
            <CustomerInfo full={true}>
              <CustomerDetailsBox full={true}>
                <CustomerImage>
                  <img
                    alt="One of our client"
                    src={require('@images/client.png')}
                  />
                </CustomerImage>

                <CustomerDetails>
                  <CustomerName>Simon Barnes</CustomerName>
                  <CustomerPosition>VP of Technology</CustomerPosition>
                </CustomerDetails>
              </CustomerDetailsBox>
              <CustomerLogo
                alt="One of our customer logo"
                src={require('@images/clients/mandiri-bank.png')}
                srcSet={`${require('@images/clients/mandiri-bank.png')}, ${require('@images/clients/mandiri-bankx2.png')} 2x`}
              />
            </CustomerInfo>
          </TestimonialBox>
        </Section>
      </Container> */}
    </>
  );
};

export default Clients;
