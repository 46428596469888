import React from 'react';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import Clients from '@components/pages/clients/index';

const ClientsPage = props => {
  return (
    <Layout {...props}>
      <SEO title="Clients" />
      <Clients />
    </Layout>
  );
};

export default ClientsPage;
